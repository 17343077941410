import { User } from "firebase/auth";
import { Observable as O } from "jazzi-observable"
import { Observable } from "jazzi-observable/_types";
import { Profile, profileCodec, TabEntryRecord, toUserId, UserId } from "../../core/models/Profile";
import { TabId } from "../../core/models/Tab";
import { Kind } from "../../core/structures/jazzi/kind";
import { TabService } from "../Tabs/tab.service";

class UnknownError extends Kind("UnknownError")<{ reason: unknown }> {}

class MissingUser extends Kind("MissingUser")<{ id: UserId }> {}
type TabListError = MissingUser | UnknownError
type TabListObservable = Observable<TabEntryRecord, TabListError>

export class ProfileService {
    static async profileExists(userId: UserId): Promise<boolean> {
        return Profile.ref().dive(userId).exists()
    }

    static async verify(creds: User) {
        const userRef = Profile.ref().dive(toUserId(creds.uid))
        const exists = await userRef.exists()
        if( !exists ){
            return userRef.set(Profile.fromUser(creds))
        }
    }
    
    static async getProfile(userId: UserId) {
        const profile = await Profile.ref()
            .dive(userId)
            .getSnapshot();

        return profile.map(p => profileCodec.encode(p).getRight())
    }

    static async leaveTab(userId: UserId, tabId: TabId){
        const ref = Profile
            .ref()
            .dive(userId)
            .dive("tabs")
            .dive(tabId)
        await ref.remove()
        return TabService.removeMember(tabId, userId)
    }

    static async joinTab(tabId: TabId, user: Profile){
        await TabService.verifyTab(tabId)
        const entry = Profile.toUserEntry(user)
        const tabEntry = await TabService.getTabEntry(tabId)
        await TabService.addMember(tabId, entry)
        return Profile
            .ref()
            .dive(user.id)
            .dive("tabs")
            .dive(tabId)
            .set(tabEntry.get())
    }

    static observeUserTabs(userId: UserId) {
        return Profile.ref().dive(userId).dive("tabs").observe()
            .catchError(e => O.throwError(new UnknownError({ reason: e })))
            .map(data => data.onNone({} as TabEntryRecord)) as TabListObservable
    }
}