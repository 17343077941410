import AppBase from './components/AppBase';
import { Route, Router } from './components/Router';
import { useRoute } from './core/state/route';
import Home from './views/Home';
import Login from './views/Login';
import TabDetails from './views/TabDetails';

function App() {
  const [route] = useRoute()
  
  return <AppBase>
    <Router current={route} >
      <Route path='login'>
        <Login />
      </Route>
      <Route path='home'>
        <Home />
      </Route>
      <Route path='tab'>
        <TabDetails />
      </Route>
    </Router>
  </AppBase>
}

export default App;
