import { tagged } from "../../core/utils/components";
import gcn from "getclassname";
import "./styles.scss";
import { AlignItems, JustifyContent, FlexDirection } from "../../core/utils/style-types";
interface FlexProps {
  $align?: AlignItems
  $justify?: JustifyContent
  $direction?: FlexDirection
  $fullsize?: boolean
  $wide?: boolean
}

const getFlexClass = (props: FlexProps) => gcn({
  base: "one-flex",
  [`&--justify-${props.$justify ? props.$justify.replace(" ","-") : "flex-start"}`]: true,
  [`&--align-${props.$align ? props.$align.replace(" ","-") : "flex-start"}`]: true,
  [`&--direction-${props.$direction ?? "row"}`]: true,
  [`&--fullsize`]: props.$fullsize,
  [`&--wide`]: props.$wide
})

const Flex = tagged.div`${getFlexClass}`

Flex.displayName = "Flex"

type CenteredProps = Omit<React.PropsWithChildren<FlexProps>, "$justify" | "$align">

export const Centered = (ps: CenteredProps) => <Flex {...ps} $justify="center" $align="center"/>

Centered.displayName = "Centered"

export default Flex