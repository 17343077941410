import { 
    AppBar, 
    Avatar, 
    Box, 
    Button,
    CardContent,
    Divider,
    Fade, 
    Grid, 
    IconButton, 
    List, 
    ListItem, 
    ListItemAvatar, 
    ListItemText, 
    Toolbar, 
    Typography 
} from "@mui/material";
import BackIcon from "@mui/icons-material/ArrowLeft"
import CopyIcon from "@mui/icons-material/ContentCopy"
import { useEffect, useState, Fragment } from "react";
import { Tab } from "../../core/models/Tab";
import { goHome, useSelectedTab } from "../../core/state/navigate";
import { TabService } from "../../services/Tabs/tab.service";
import { Container } from "@mui/system";
import { entries } from "../../core/utils/functions";
import { useBooleanStates, useMaybe } from "../../core/utils/hooks";
import ExpandableCard from "../../components/ExpandableCard";
import ExpandablePayment from "../../components/ExpandablePayment";
import SummaryBalance from "../../components/SummaryBalance";
import { PaymentForm } from "../../components/Dialogs";

interface CopyProps {
    active: boolean
    onClick: () => void
}

const CopyComp = ({ active, onClick }: CopyProps) => <IconButton onClick={onClick}>
    <CopyIcon color={active ? "primary" : undefined}/>
</IconButton>

interface DetailsProps {
    tab: Tab
}

const detailsFlags = ["tabId", "members", "balances", "history", "paymentDialog"] as const
const Details = ({ tab }: DetailsProps) => {
    const [flags, triggerFlag] = useBooleanStates(detailsFlags)
    const [showCopied, setShowCopied] = useState(false);
    const handleCopy = () => {
        navigator.clipboard
            .writeText(tab.id)
            .then(() => {
                setShowCopied(true)
                setTimeout(() => setShowCopied(false), 2000)
            })
    }
    const payments = entries(tab.entries)
        .map(a => a[1])
        .sort((a,b) => new Date(a.timestamp) < new Date(b.timestamp) ? 1: -1 )
    const summary = Tab.getSummary(tab)
    const members = entries(tab.members).map(([, a]) => a)

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <ExpandableCard
                expanded={flags.tabId}
                title="Tab ID"
                subheader="use this code to join the tab"
                onTrigger={triggerFlag("tabId")}
            >
                <CardContent>
                    <Typography variant="h6" component="div">
                        {tab.id} 
                        <CopyComp active={showCopied} onClick={handleCopy}/>
                        <Fade in={showCopied}>
                            <Typography sx={{ fontSize: "10px" }}  component="span">Copied!</Typography>
                        </Fade>
                    </Typography>
                </CardContent>
            </ExpandableCard>
        </Grid>
        <Grid item xs={12}>
            <Button
                variant="contained"
                color="secondary"
                onClick={triggerFlag("paymentDialog")}
                sx={{
                    width: "100%"
                }}
            >Add a Payment</Button>
        </Grid>
        <Grid item xs={12}>
            <ExpandableCard
                title="Members"
                expanded={flags.members}
                onTrigger={triggerFlag("members")}   
            >
                <CardContent>
                    <List>
                        {members.map(({ id, picture, fullname, active }) => {
                            return <ListItem key={id}>
                                <ListItemAvatar>
                                    <Avatar alt={fullname} src={picture}/>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={fullname} 
                                    secondary={
                                        <Typography color={active ? "success.main": "error"}>
                                            {active ? "Active" : "Inactive"}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        })}
                    </List>
                    </CardContent>
            </ExpandableCard>
        </Grid>
        <Grid item xs={12}>
            <ExpandableCard
                title="Balances"
                expanded={flags.balances}
                onTrigger={triggerFlag("balances")}
            >
                <CardContent>
                    <List>
                        {entries(tab.members).map(([id, member]) => {
                            return <ListItem key={id}>
                                <ListItemAvatar>
                                    <Avatar alt={member.fullname} src={member.picture}/>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={member.fullname} 
                                    secondary={<SummaryBalance value={summary[id]}/>}
                                    />
                            </ListItem>
                        })}
                    </List>
                </CardContent>
            </ExpandableCard>
        </Grid>
        <Grid item xs={12}>
            <ExpandableCard
                title="History"
                expanded={flags.history} 
                onTrigger={triggerFlag("history")}                
            >
                <CardContent>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {payments.length === 0 
                        ? <Typography>No payments registered yet</Typography>    
                        : payments.map((entry, index) => {
                            if( index === payments.length - 1){
                                return <ExpandablePayment key={entry.id} entry={entry} members={tab.members} />
                            }
                            return <Fragment key={entry.id}>
                                <ExpandablePayment key={entry.id} entry={entry} members={tab.members} />
                                <Divider />
                            </Fragment>
                        })}
                    </Box>
                </CardContent>
            </ExpandableCard>
        </Grid>
        <PaymentForm 
            open={flags.paymentDialog}
            members={tab.members}
            onCancel={triggerFlag("paymentDialog")}
            onSubmit={data => {
                TabService.addPayment(tab.id, data)
                triggerFlag("paymentDialog")()
            }}
        />
    </Grid>
}

const TabDetails = () =>  {
    const tabEntry = useSelectedTab()
    const [tabData, setTab] = useMaybe<Tab>()
    useEffect(() => {
        return TabService
            .observeTab(tabEntry.id)
            .fmap(setTab)
            .subscribe()
    }, [tabEntry, setTab])

    return <Fade in timeout={{ enter: 1000 }}>
        <div>
            <AppBar sx={{ position: "fixed" }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => goHome()}
                    >
                        <BackIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {tabEntry.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container 
                maxWidth="lg" 
                sx={{ 
                    position: "relative", 
                    flexGrow: "1", 
                    height: "1fr",
                    paddingTop: "70px",
                    paddingBottom: "40px",
                    overflow: "visible"
                }}
            >
                {tabData.fold(
                    () => <></>,
                    (data) => <Details tab={data}/>
                )}
            </Container>
        </div>
    </Fade>
}

export default TabDetails;