import { serverTimestamp } from "firebase/database";
import { Observable as O } from "jazzi-observable"
import { Observable } from "jazzi-observable/_types";
import { Profile, UserEntry, UserId } from "../../core/models/Profile";
import { Entry, Tab, tabCodec, TabId, toEntryId, toTabId } from "../../core/models/Tab";
import { Kind } from "../../core/structures/jazzi/kind";
import { entries } from "../../core/utils/functions";

class UnknownError extends Kind("UnknownError")<{ reason: unknown }> {}
class MissingTab extends Kind("MissingTab")<{ id: TabId }> {}
type TabError = MissingTab | UnknownError
type TabObservable = Observable<Tab, TabError>

export class TabService {
    static async createTab(name: string, description: string, user: Profile) {
        const ref = Tab.ref()
        const key = await ref.create()
        const tab = Tab.from(name, description, user);
        const newTab = { ...tab, id: toTabId(key) }
        await ref.dive(newTab.id).set(newTab)
        return Profile.ref().dive(user.id).dive("tabs").dive(newTab.id).set(Tab.toTabEntry(newTab))
    }

    static async removeMember(tabId: TabId, userId: UserId){
        const tabRef = Tab.ref().dive(tabId)
        const membersRef = tabRef.dive("members")
        const memberRef = membersRef.dive(userId)
        await memberRef.dive("active").set(false)
        const m = await membersRef.getSnapshot()
        const hasActiveMembers =  m
            .map(x => entries(x).map(([, e]) => e))
            .map(x => x.some(m => m.active))
            .onNone(() => false)
        if( !hasActiveMembers ){
            return tabRef.remove();
        }
    }

    static async verifyTab(tabId: TabId){
        const exists = await Tab.ref().dive(tabId).exists()
        if( !exists ){
            throw new MissingTab({ id: tabId })
        }
    }

    static async addMember(tabId: TabId, user: UserEntry){
        const ref = Tab.ref().dive(tabId)
        return ref
            .dive("members")
            .dive(user.id)
            .set(user)
    }

    static async getTabEntry(tabId: TabId){
        const tab = await Tab.ref().dive(tabId).getSnapshot()
        return tab.map(Tab.toTabEntry)
    }

    static async addPayment(tabId: TabId, entry: Omit<Entry, "id" | "timestamp">){
        const tabEntries = Tab.ref().dive(tabId).dive("entries")
        const id = toEntryId(await tabEntries.create())
        return tabEntries.dive(id).set({ 
            ...entry, 
            timestamp: serverTimestamp() as any,
            id 
        })
    }

    static observeTab(tabId: TabId): TabObservable {
        return Tab.ref()
            .dive(tabId)
            .observe()
            .flatMap(data => {
                if( data.isJust() ){
                    return O.of(tabCodec.encode(data.get()).getRight()) as any
                } else {
                    return O.throwError(new MissingTab({ id: tabId })) as any
                }
            }) as TabObservable
    }
}