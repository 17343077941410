import { TextField, TextFieldProps } from "@mui/material"
import { Formatter, toFormatted } from "../../core/structures/formatter/formatter"

export type FormattedTextFieldProps = Omit<TextFieldProps, "onChange"> & {
    formatter: Formatter
    value: string
    onChange: (value: string) => void
}

const FormattedTextField = ({
    formatter,
    value,
    onChange,
    ...props
}: FormattedTextFieldProps) => {
    return <TextField 
        {...props} 
        value={formatter.view(value)}
        onChange={e => onChange?.(formatter.review(toFormatted(e.target.value)))}
    />
}

export default FormattedTextField