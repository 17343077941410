import { useEffect, useState } from "react";
import CircularButton from "../../components/CircularButton";
import { Centered } from "../../components/Flex";
import Loader from "../../components/Loader";
import { useRoute } from "../../core/state/route";
import { useUserSet } from "../../core/state/user";
import { useLazyPromise, useLazyTimeout } from "../../core/utils/hooks";
import { AuthService } from "../../services/Auth/auth.service";
import { toUserId, UserId } from "../../core/models/Profile";
import "./styles.scss"

type LoginState = "waiting" | "loading" | "success" | "error";

const Login = () => {
    const [, setRoute] = useRoute()
    const setUser = useUserSet()
    const [state, setState] = useState<LoginState>("loading")
    const authenticate = useLazyPromise(() => AuthService.authenticate())
    const profileFetch = useLazyPromise((id: UserId) => AuthService.getProfile(id))
    const triggerRoute = useLazyTimeout(() => setRoute("home"), 500);

    useEffect(() => {
        if( profileFetch.state === "success" ){
            setState("success")
            setUser(profileFetch.data)
            triggerRoute()
        }
        if( profileFetch.state === "error" ){
            setState("waiting")
        }
    }, [profileFetch, setUser, triggerRoute])

    useEffect(() => {
        AuthService
            .verify()
            .then(
                (data) => {
                    if( data.loggedIn ){
                        profileFetch.runPromise(toUserId(data.user.uid));
                    } else {
                        setState("waiting")
                    }
                }, 
                () => setState("waiting")
            )
        // eslint-disable-next-line
    },[])

    const handleClick = () => {
        if( state === "waiting" ){
            setState("loading");
            authenticate.runPromise(undefined)
        }
    }
    const UI = state === "loading" 
        ? <Loader $alternate /> 
        : state === "waiting" 
            ? <>Login</>
            : <></>

    return <div className="one-login"><Centered $fullsize>
        <CircularButton 
            onClick={handleClick} 
            $fill={state !== "waiting"} 
            $white={state === "success"}
        >
            {UI}
        </CircularButton>
    </Centered></div>
}

export default Login;