export class Iso<A,B> {
    private constructor(
        public view: (a: A) => B,
        public review: (b: B) => A
    ){}

    public static make<A,B>(
        view: (a: A) => B,
        review: (b: B) => A
    ){
        return new Iso(view, review);
    }

    public ['>>>']<C>(other: Iso<B,C>){
        return new Iso(
            (a: A) => other.view(this.view(a)),
            (c: C) => this.review(other.review(c))
        )
    }

    public combine<C>(other: Iso<B,C>){
        return this[">>>"](other);
    }
}