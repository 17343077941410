import { Either } from "jazzi/dist/Either/types"

export interface DecodeError {
    type: "decode"
    error: unknown
}

export interface EncodeError {
    type: "encode"
    error: unknown
}

export type CodecError = DecodeError | EncodeError

type RefineError<T extends CodecError['type']> = T extends "encode" ? EncodeError : DecodeError

export const toErr = <T extends "encode" | "decode">(type: T) => (error: unknown): RefineError<T> => ({ type, error } as RefineError<T>)

export interface Decoder<A,B> {
    decode(b: B): Either<DecodeError, A>
}

export interface Encoder<A,B> {
    encode(a: A): Either<EncodeError, B>
}

export interface Codec<A,B> extends Encoder<A,B>, Decoder<A,B> {
    get decoder(): Decoder<A,B>;
    get encoder(): Encoder<A,B>;
    chain<C>(other: Codec<B,C>): Codec<A,C>;
}

interface CodecDefinition<A,B> {
    decode(b: B): Either<unknown, A>
    encode(a: A): Either<unknown, B>
}

export const buildCodec = <A,B>(base: CodecDefinition<A,B>): Codec<A,B> => ({
    get encoder(){
        return { encode: (a: A) => this.encode(a) }
    },
    get decoder(){
        return { decode: (b: B) => this.decode(b) }
    },
    decode(b: B): Either<DecodeError, A> {
        return base.decode(b).mapLeft(toErr("decode"))
    },
    encode(a: A): Either<EncodeError, B> {
        return base.encode(a).mapLeft(toErr("encode"))
    },
    chain<C>(other: Codec<B,C>){
        const self = this;
        return buildCodec({
            encode(a: A): Either<EncodeError, C> {
                return self.encode(a).chain(data => other.encode(data))
            },
            decode(c: C): Either<DecodeError, A> {
                return other.decode(c).chain(data => self.decode(data))
            }
        })
    }
})

export const chainCodec = <A,B,C>(ac: Codec<A,B>, bc: Codec<B,C>): Codec<A,C> => ac.chain(bc)