const dataSymbol = Symbol()

interface HasKind<K extends string> {
    kind: K
}

export const Kind = <KindT extends string>(kind: KindT) => {
    return class _Kind<Data> implements HasKind<KindT> {
        public readonly kind: KindT = kind;
        private [dataSymbol]: Data;

        constructor(data: Data){
            this[dataSymbol] = data
        }

        public get<Key extends keyof Data>(key: Key): Data[Key] {
            return this[dataSymbol][key]
        }
    }
}