import { TabEntry } from "../models/Profile";
import { Tab } from "../models/Tab"
import { createAtom, useAtomValue } from "../structures/jazzi/atom"
import { RouteAtom } from "./route";

export const NavigationAtom = createAtom({ tab: Tab.toTabEntry(Tab.empty()) });

export const goToTabDetails = (tab: TabEntry) => {
    RouteAtom.set("tab")
    NavigationAtom.set({ tab })
}

export const goHome = () => {
    RouteAtom.set("home")
    NavigationAtom.set({ tab: Tab.toTabEntry(Tab.empty()) })
}

export const useSelectedTab = () => useAtomValue(NavigationAtom).tab