import React, { useState } from "react"
import { Typography,
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Box,
    Fab,
    Zoom,
    Divider,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import AddIcon from "@mui/icons-material/Add"
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from "@mui/icons-material/Logout"
import { useRoute } from "../../core/state/route"
import { useLazyPromise, useLazyTimeout } from "../../core/utils/hooks"
import { AuthService } from "../../services/Auth/auth.service"
import TabList from "../TabList"
import { TabService } from "../../services/Tabs/tab.service";
import { useUserAtom } from "../../core/state/user";
import { SimpleDialogForm } from "../../components/Dialogs";
import { ProfileService } from "../../services/Profile/profile.service";
import { toTabId } from "../../core/models/Tab";
import Version from "../../components/Version";
import "./styles.scss"

const Home = () => {
    const [, setRoute] = useRoute()
    const [userM] = useUserAtom()
    const doLogout = useLazyPromise(() => AuthService.logout())
    const [cl, setClass] = useState("one-home")
    const [drawer, setDrawer] = useState(false)
    const triggerLoginRoute = useLazyTimeout(() => setRoute("login"), 500);

    const [createModal, setCreateModal] = useState(false);
    const [joinModal, setJoinModal] = useState(false);

    const handleMenuAction = (kind: "logout" | "create" | "join"): React.MouseEventHandler => (e) => {
        e.stopPropagation();
        setDrawer(false);
        switch(kind){
            case "create":
                setCreateModal(true)
                break;
            case "join":
                setJoinModal(true)
                break;
            case "logout":
                setClass("one-home one-home--fadeOut")
                if( doLogout.state === "waiting" ){
                    doLogout.runPromise(undefined)
                }
                break;
        }
    }

    if( doLogout.state === "success" ){
        triggerLoginRoute()
    }

    return <div className={cl} onClickCapture={(e) => {
        if( cl.includes("fadeOut") ){
            e.stopPropagation();
            e.preventDefault();
        }
    }}>
        <AppBar sx={{ position: "fixed" }}>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => setDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Tabs
                </Typography>
            </Toolbar>
        </AppBar>
        <Drawer
            anchor="left" 
            open={drawer}
            onClose={() => setDrawer(false)}
        >
            <Box sx={{ width: 250, boxSizing: "border-box", height: "100%" }}>
                <List sx={{ display: "flex", flexDirection: "column", height: "100%", boxSizing: "border-box"}}>
                    <ListItemButton sx={{ flex: 0 }} onClick={handleMenuAction("create")}>
                        <ListItemIcon><AddIcon color="success"/></ListItemIcon>
                        <ListItemText>Create Tab</ListItemText>
                    </ListItemButton>
                    <ListItemButton sx={{ flex: 0 }} onClick={handleMenuAction("join")}>
                        <ListItemIcon><CardMembershipIcon color="primary"/></ListItemIcon>
                        <ListItemText>Join</ListItemText>
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ flex: 0 }} onClick={handleMenuAction("logout")}>
                        <ListItemIcon><LogoutIcon color="error" /></ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </ListItemButton>
                    <ListItem sx={{ flex: 0, marginTop: "auto" }}>
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText><Version /></ListItemText>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
        <SimpleDialogForm 
            open={createModal}
            title="Create a new Tab"
            submitText="Create"
            fields={{
                name: "Tab Name",
                description: "Description"
            } as const}
            onClose={() => setCreateModal(false)}
            onSubmit={({ name, description }) => {
                TabService.createTab(name, description, userM.get())
                setCreateModal(false)
            }}
        />
        <SimpleDialogForm 
            open={joinModal}
            title="Join a Tab"
            submitText="Join"
            fields={{
                id: "Tab ID"
            }}
            onClose={() => setJoinModal(false)}
            onSubmit={({ id }) => {
                ProfileService.joinTab(toTabId(id), userM.get())
                setJoinModal(false)
            }}
        />
        <TabList />
        <Zoom
            in
            style={{
                transitionDelay: "100ms"
            }}
        >
            <Fab 
                color="success" 
                aria-label="create" 
                size="large"
                sx={{ position: "fixed", right: 24, bottom: 32 }}
                onClick={handleMenuAction("create")}
            >
                <AddIcon />
            </Fab>
        </Zoom>
    </div>
}

export default Home