import { Either } from "jazzi";
import { Lens } from "../structures/optics/lens"
import { buildCodec } from "../structures/codec/codec";
import { NewType, to, from } from "../structures/jazzi/newType";
import { TabId } from "./Tab";
import { smartRef } from "../../integration/firebase/database";
import { User } from "firebase/auth";

export type UserId = NewType<string>
export const toUserId = to<UserId>
export const fromUserId = from<UserId>

export interface UserEntry {
    id: UserId,
    fullname: string,
    picture: string,
    active: boolean
}

export interface TabEntry {
    id: TabId,
    name: string,
    description: string,
}

export type TabEntryRecord = Record<string, TabEntry>

export type ProfileRecord = Record<UserId, Profile>

export class Profile {
    constructor(
        public fullname: string,
        public id: UserId,
        public picture: string,
        public tabs: TabEntryRecord
    ){}

    static toUserEntry(user: Profile): UserEntry {
        return {
            id: user.id,
            fullname: user.fullname,
            picture: user.picture,
            active: true
        }
    }

    static fromUser(user: User): Profile {
        return new Profile(
            user.displayName ?? "",
            user.uid as UserId,
            user.photoURL ?? "",
            {}
        )
    }

    static ref() {
        return smartRef<ProfileRecord>("users");
    }
}

const tabLens = Lens.make<Profile, TabEntryRecord>(
    (p) => p.tabs,
    (p, tabs) => new Profile(p.fullname, p.id, p.picture, tabs)
)

export const profileCodec = buildCodec({
    encode(a: Profile){
        if( a.tabs === undefined ){
            return Either.Right(tabLens.update(() => ({}))(a))
        } 
        return Either.Right(a)
    },
    decode(a: Profile){ return Either.Right(a) }
})