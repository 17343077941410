import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';

interface ExpandMoreProps {
    expanded: boolean,
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

const Expand = ({ expanded, onClick }: ExpandMoreProps) => {
    return <IconButton 
        sx={{
            transition: "transform 100ms linear",
            transform: expanded ? "rotateX(180deg)" : "rotateX(0deg)"
        }}
        onClick={onClick}
    >
        <ExpandMoreIcon />
    </IconButton>
}

export default Expand