import { Either } from "jazzi";
import { smartRef } from "../../integration/firebase/database";
import { buildCodec } from "../structures/codec/codec";
import { NewType, to, from } from "../structures/jazzi/newType";
import { entries } from "../utils/functions";
import { Profile, TabEntry, UserEntry, UserId } from "./Profile";

export type EntryId = NewType<string>
export const toEntryId = to<EntryId>
export const fromEntryId = from<EntryId>

export class Entry {
    constructor(
        public id: EntryId,
        public timestamp: number,
        public changes: Record<UserId, number>,
        public note: string,
        public total: number,
        public payer: UserId,
        public percentages: Record<UserId, number>
    ){}
}

export type TabId = NewType<string>
export const toTabId = to<TabId>
export const fromTabId = from<TabId>

export type EntryRecord = Record<EntryId, Entry>
export type TabRecord = Record<TabId, Tab>
export type UserRecord = Record<UserId, UserEntry>
export type Summary = Record<UserId, number>

export class Tab {
    constructor(
        public id: TabId,
        public name: string,
        public owner: string,
        public description: string,
        public members: UserRecord,
        public entries: EntryRecord
    ){
    }

    static partialTab(){
        return {} as Partial<Tab>
    }

    static empty(){
        return new Tab(toTabId(""), "", "", "", {}, {}) 
    }

    static from(name: string, description: string, profile: Profile) {
        const partial = this.partialTab();
        partial.name = name
        partial.owner = profile.id
        partial.members = { [profile.id]: Profile.toUserEntry(profile) }
        partial.entries = {}
        partial.description = description
        return partial as Omit<Tab, "id">
    }

    static toTabEntry(tab: Tab): TabEntry {
        return {
            id: tab.id,
            name: tab.name,
            description: tab.description
        }
    }

    static fromTabEntry({ id, description, name }: TabEntry){
        new Tab(id, name, "", description, {}, {})
    }

    static getSummary(tab: Tab): Summary {
        const initialValues = entries(tab.members).reduce((acc, [key]) => {
            acc[key] = 0;
            return acc
        },{} as Summary)
        return entries(tab.entries)
            .map(([, entry]) => entry.changes)
            .reduce((a,b) => {
                const aKeys = Object.keys(a)
                const bKeys = Object.keys(b)
                const keys = new Set([...aKeys, ...bKeys])
                const get = (key: string, obj: Record<string, number>) => obj[key] ?? 0
                return Array.from(keys).reduce((acc, next) => {
                    acc[next] = get(next, a) + get(next, b)
                    return acc
                }, {} as Record<string, number>)
            }, initialValues)
    }

    static ref() {
        return smartRef<TabRecord>("tabs")
    }
}


export const tabCodec = buildCodec({
    encode(a: Tab){
        if( !a.entries ){
            a.entries = {}
        }
        if( !a.members ){
            a.members = {}
        }
        return Either.Right(a)
    },
    decode(a: Tab){ return Either.Right(a) }
})