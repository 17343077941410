import { app } from "./firebase"
import { 
  GoogleAuthProvider, 
  getAuth,
  signOut, 
  setPersistence, 
  browserLocalPersistence, 
  signInWithRedirect,
  getRedirectResult,
} from 'firebase/auth'

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "consent" })
const auth = getAuth(app);

class SignInError {
    constructor(public reason: unknown){}
}

export const getCurrentUser = () => auth.currentUser

export const signInWithGoogle = () => setPersistence(auth, browserLocalPersistence)
  .then(() => signInWithRedirect(auth, provider))

export const getResult = () => getRedirectResult(auth)
  .then((raw) => raw === null ? Promise.reject(new SignInError("null creds")): raw)

export const signOutGoogle = () => signOut(auth)