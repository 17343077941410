export type LensGet<S,A> = (s: S) => A
export type LensSet<S,A> = (s: S, a: A) => S

export function shallowSet<T, K extends keyof T>(attr: K): (s: T, a: T[K]) => T {
    return (s: T, a: T[K]) => ({ ...s, [attr]: a })
}

export function shallowGet<T, K extends keyof T>(attr: K): (s: T) => T[K] {
    return (s: T) => s[attr]
}

export class Lens<S,A> {
    private constructor(private getter: LensGet<S,A>, private setter: LensSet<S,A>){}

    static make<S,A>(getter: LensGet<S,A>, setter: LensSet<S,A>){
        return new Lens(getter, setter)
    }

    static makeFrom<T, K extends keyof T>(attr: K) {
        return new Lens<T, T[typeof attr]>(shallowGet(attr), shallowSet(attr))
    }

    public read(s: S): A {
        return this.getter(s)
    }

    public update(fn: (a: A) => A): (s: S) => S {
        return (s: S) => this.setter(s, fn(this.getter(s)))
    }

    public toConstant(c: A): (s: S) => S {
        return this.update(() => c)
    }

    public ['>>>']<B>(other: Lens<A,B>): Lens<S,B> {
        return new Lens(
            (s: S) => other.getter(this.getter(s)),
            (s: S, b: B) => this.setter(s, other.setter(this.getter(s), b))
        )
    }

    public combine<B>(other: Lens<A,B>): Lens<S,B> {
        return this[">>>"](other)
    }

}