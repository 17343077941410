import { useEffect, useState } from "react"
import { Container, Card, CardContent, Typography, Grid, CardActions, IconButton } from "@mui/material"
import OpenIcon from "@mui/icons-material/OpenWith"
import DeleteIcon from "@mui/icons-material/Delete"
import { TabId } from "../../core/models/Tab"
import { useUserAtom } from "../../core/state/user"
import { ProfileService } from "../../services/Profile/profile.service"
import { TabEntry, TabEntryRecord } from "../../core/models/Profile"
import { Prompt } from "../../components/Dialogs"
import { goToTabDetails } from "../../core/state/navigate"

interface TabCardProps {
    id: TabId,
    name: string,
    description: string,
    onOpen: (tab: TabEntry) => void,
    onDelete: (tab: TabEntry) => void
}

const TabCard = ({ onOpen, onDelete, ...tabEntry }: TabCardProps) => {
    const { name, description } = tabEntry
    return <Card>
        <CardContent>
            <Typography variant="h5">{name}</Typography>
            <Typography variant="subtitle1">{description}</Typography>
        </CardContent>
        <CardActions sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <IconButton onClick={() => onOpen(tabEntry)}>
                <OpenIcon />
            </IconButton>
            <IconButton onClick={() => onDelete(tabEntry)}>
                <DeleteIcon />
            </IconButton>
        </CardActions>
    </Card>
}

const EmptyTabs = () => <Card>
    <CardContent>
        <Typography variant="h6">
            You have no tabs. 
        </Typography>
        <Typography variant="body1">
            Either create or join a tab.
        </Typography>
    </CardContent>
</Card>

const TabList = () => {
    const [visible, setVisible] = useState(false)
    const [tabRecord, setTabRecord] = useState<TabEntryRecord>({})
    const [deletePromptOpen, setDeletePropmtOpen] = useState(false)
    const [tabToDelete, setTabToDelete] = useState<TabEntry | undefined>(undefined)
    const [profileM] = useUserAtom()

    const userProfile = profileM.get()
    
    useEffect(() => {
        return profileM
            .map(({ id }) => {
                const tabObservable = ProfileService.observeUserTabs(id)
                return tabObservable
                    .fmap(data => {
                        setVisible(true)
                        setTabRecord(data)
                    })
                    .subscribe()
            })
            .onNone(() => () => {})
    },[profileM])

    const handleAction = (kind: "delete" | "open") => (tab: TabEntry) => {
        if( kind === "delete" ){
            setDeletePropmtOpen(true)
            setTabToDelete(tab);
        } else {
            goToTabDetails(tab)
        }
    }

    const tabRecordKeys = Object.keys(tabRecord)
    const content = tabRecordKeys.length === 0 
        ? <Grid item xs={12}>
            <EmptyTabs />
        </Grid>
        : tabRecordKeys.map(key => tabRecord[key]).map((tab) => {
            return <Grid key={tab.id} item xs={12}>
                    <TabCard 
                        {...tab} 
                        onDelete={handleAction("delete")} 
                        onOpen={handleAction("open")} 
                    />
                </Grid>
        })

    return <Container 
        maxWidth="lg" 
        sx={{ 
            position: "relative", 
            flexGrow: "1", 
            height: "1fr",
            paddingTop: "70px",
            paddingBottom: "40px",
            overflow: "visible"
        }}
    >
        <Prompt 
            open={deletePromptOpen}
            title="Are you sure you want to leave?"
            text={`You are about to leave the tab with name "${tabToDelete?.name}"`}
            successText="Delete"
            onCancel={() => setDeletePropmtOpen(false)}
            onSuccess={() => {
                setDeletePropmtOpen(false)
                ProfileService.leaveTab(userProfile.id, tabToDelete?.id!)
            }}
        />
        <Grid 
            container 
            spacing={2} 
            sx={{ 
                position: "relative",
                transition: "all 300ms ease-out",
                opacity: visible ? 1: 0
            }}
        >
            {content}
        </Grid>
    </Container>
}

export default TabList