import { FunctionComponent } from "react"
import { interesperse } from "./functions"

const intrinsicElements = [
    "abbr","a","address","area","article","aside","audio","b","base","bdi","bdo","big","blockquote","body","br",
    "button","canvas","caption","cite","code","col","colgroup","data","datalist","dd","del","details","dfn","dialog",
    "div","dl","dt","em","embed","fieldset","figcaption","figure","footer","form","h1","h2","h3","h4","h5","h6",
    "head","header","hgroup","hr","html","i","iframe","img","input","ins","kbd","keygen","label","legend","li",
    "link","main","map","mark","menu","menuitem","meta","meter","nav","noindex","noscript","object","ol","optgroup",
    "option","output","p","param","picture","pre","progress","q","rp","rt","ruby","s","samp","slot","script","section","select",
    "small","source","span","strong","style","sub","summary","sup","table","template","tbody","td","textarea","tfoot","th","thead",
    "time","title","tr","track","u","ul","var","video","wbr","webview",
] as const

type IntrinsicElement = typeof intrinsicElements[number]

type SimpleComponent<Props = {}> = FunctionComponent<React.PropsWithChildren<Props>>

type ComponentFactory = <T>(className: string) => SimpleComponent<T>

type ComponentTag = <T>(text: TemplateStringsArray, ...interpolations: Interpolation<T>[]) => SimpleComponent<T>

type IntrinsicOf<T> = Record<IntrinsicElement, T>

interface SimpleComponents extends IntrinsicOf<ComponentFactory> {}

interface SimpleTags extends IntrinsicOf<ComponentTag> {}

const removeProps = <T extends object>(props: T) => Object.fromEntries(Object.entries(props).filter(([key]) => !key.startsWith("$")))

const componentFn = (tag: IntrinsicElement) => <T,>(className: string) => (props: React.PropsWithChildren<T>) => {
    const Comp = tag
    let computedCl = className;
    if( hasClassName(props) ){
        computedCl = `${className} ${props.className}`
    }
    return <Comp {...removeProps(props)} className={computedCl} />
}

type Interpolation<T> = (props: T) => string

type PropsWithClassName = { className: string }
const hasClassName = <T,>(x: T): x is T & PropsWithClassName => { return "className" in x }

const componentTag = (tag: IntrinsicElement) => <T,>(str: TemplateStringsArray, ...interpolations: Interpolation<T>[]) => {
    const Comp = tag;
    const text = Array.from(str)
    return (props: React.PropsWithChildren<T>) => {
        const cl = interesperse(text, interpolations.map(i => i(props))).join("")
        let computedCl = cl;
        if( hasClassName(props) ){
            computedCl = `${cl} ${props.className}`
        }
        return <Comp {...removeProps(props)} className={computedCl}/>
    }
}

export const component: SimpleComponents = 
    intrinsicElements.reduce((acc, key) => {
        acc[key] = componentFn(key)
        return acc
    }, {} as SimpleComponents)
    
export const tagged: SimpleTags =
    intrinsicElements.reduce((acc, key) => {
        acc[key] = componentTag(key)
        return acc
    }, {} as SimpleTags)