import { useEffect, useState, useSyncExternalStore } from "react"
import { compose } from "../../utils/functions"
import { SetAction, Setter } from "../../utils/types"
import { Lens } from "../optics/lens"

type Subscriber<T> = {
    id: number,
    notify: (data: T) => void
} 

type Unsubscribe = () => void

type Eq<A> = (a: A, b: A) => boolean

const simpleEq = <A>(a: A, b: A) => a === b

interface Derive<T,U> {
    map: (src: T) => U
    contraMap: (src: U) => T
}

export interface Atom<T> {
    get: () => T,
    set: (action: SetAction<T>) => void
    subscribe: (next: (data: T) => void) => Unsubscribe
    derive: <U>(derivation: Derive<T,U>) => Atom<U>
}


const DerivedAtom = <T,U>(src: Atom<T>, derivation: Derive<T,U>): Atom<U> => {
    const { map, contraMap } = derivation

    return {
        get: () => map(src.get()),
        set(action: SetAction<U>) {
            const next = action instanceof Function ? action(this.get()) : action
            src.set(contraMap(next))
        },
        derive<V>(derivation: Derive<U,V>): Atom<V> {
            return DerivedAtom(this, derivation)
        },
        subscribe: (next: (data: U) => void) => src.subscribe(compose(next, map)),
    }
}

const PrimaryAtom = <T>(init: T | (() => T), eq: Eq<T> = simpleEq): Atom<T> => {
    let subs = [] as Subscriber<T>[]
    let currentId = 0
    let data = init instanceof Function ? init() : init;

    return {
        get: () => data,
        set: (action: SetAction<T>) => {
            let next = undefined
            if( action instanceof Function){
                next = action(data)
            } else {
                next = action
            }
            if( !eq(data, next) ){
                data = next;
                subs.forEach(sub => sub.notify(data))
            }
        },
        derive<U>(derivation: Derive<T,U>): Atom<U> {
            return DerivedAtom(this, derivation)
        },
        subscribe: (next: (data: T) => void) => {
            const sub = {
                id: currentId++,
                notify: next
            }
    
            subs.push(sub)
    
            return () => {
                subs = subs.filter(s => s.id !== sub.id)
            }
        }
    }
}

export const createAtom = <T>(data: T | (() => T), eq: Eq<T> = simpleEq) => PrimaryAtom(data, eq);

export const useAtomValue = <T>(atom: Atom<T>): T => {
    const [state, set] = useState(atom.get())

    useEffect(() => atom.subscribe(set), [atom])

    return state
}

export const useAtom = <T>(atom: Atom<T>): [T, Setter<T>] => {
    const value = useSyncExternalStore(atom.subscribe, atom.get)

    const set = atom.set;

    return [value, set]
}

export const useAtomSelector = <T, S>(atom: Atom<T>, selector: (t: T) => S): [S, Setter<T>] => {
    const [data, setData] = useAtom(atom)

    return [selector(data), setData]
}

export const useAtomView = <T,S>(atom: Atom<T>, lens: Lens<T,S>): [S, Setter<S>] => {
    const [data, setData] = useAtom(atom)

    const setter = (s: SetAction<S>) => {
        const fn = s instanceof Function ? s : () => s
        setData(prev => lens.update(fn)(prev))
    }

    return [lens.read(data), setter]
}