import { Card, CardHeader, Collapse } from "@mui/material"
import Expand from "../Expand"

interface ExpandableCardProps {
    expanded: boolean
    title: string
    subheader?: string
    children: React.ReactNode
    onTrigger: () => void
}

const ExpandableCard = ({ expanded, title, subheader, children, onTrigger}: ExpandableCardProps) => {
    const handleTrigger: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        onTrigger()
    }
    return <Card>
        <CardHeader
            title={title}
            subheader={subheader}
            onClick={handleTrigger}
            action={
                <Expand
                    expanded={expanded}
                    onClick={handleTrigger}
                />
            }
        />
        <Collapse in={expanded}>
            {children}
        </Collapse>
    </Card>
}

export default ExpandableCard