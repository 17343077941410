import { Maybe as M } from "jazzi"
import { Maybe } from "jazzi/dist/Maybe/types";
import { Profile } from "../models/Profile";
import { createAtom, useAtom } from "../structures/jazzi/atom";
import { SetAction } from "../utils/types";

const UserAtom = createAtom<Maybe<Profile>>(M.None());

export const useUserAtom = () => useAtom(UserAtom)

export const useUserSet = () => (a: SetAction<Maybe<Profile>>) => UserAtom.set(a)