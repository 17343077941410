import { getTag } from "jazzi"
import { Matcher } from "jazzi/dist/_internals/types"

type Fn<A extends any[], B> = (...args: A) => B

export const compose = <A, G extends Fn<any,any>>(f: (arg: ReturnType<G>) => A, g: G) => (...args: Parameters<G>) => f(g(...args))

export const is = <T extends string>(tag: T) => (struct: Matcher<T>): boolean => getTag(struct) === tag

export const merge = <A,B>(a: A, b: B): A & B => ({ ...a, ...b })

export const mergeTuple = <A,B>([a,b]: [A,B]) => merge(a,b)

type UnionToIntersection<U> = 
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never

type TupleIntersection<T extends [...any[]]> = UnionToIntersection<T[number]>

export const mergeAll = <T extends [...any[]]>(data: T): TupleIntersection<T> => data.reduce(merge,{})

export const prop = <K extends string>(key: K) => <T, R extends Record<K,T>>(t: R) => t[key]

export const eq = <T>(a: T) => (b: T) => a === b

export const propEq = <K extends string>(key: K) => <T, R extends Record<K,T>>(a: R, b: R) => eq(prop(key)(a))(prop(key)(b))

export const keys = <T>(t: T) => Object.keys(t as object) as (keyof T)[]

export const shallowEq = <T>(a: T, b: T) => [...keys(a), ...keys(b)].every((k) => a[k] === b[k])

export const interesperse = <T>(as: T[], bs: T[]): T[] => {
  const aLength = as.length
  const bLength = bs.length
  if( aLength === bLength ){
    return as.flatMap((a, idx) => [a, bs[idx]])
  }
  if( aLength < bLength ){
    return interesperse(as, bs.slice(0, aLength)).concat(bs.slice(aLength))
  }
  return interesperse(as.slice(0, bLength), bs).concat(as.slice(bLength))
}

export const wait = (milliseconds: number) => new Promise((res) => setTimeout(res, milliseconds))

export const entries = <T extends string, V>(rec: Record<T,V>) => Object.entries(rec) as [T, V][]

export const group = <T>(arr: T[], n: number): T[][] => {
  const digits = arr.reverse();
  let res = [] as T[][]
  let current = [] as T[]
  for(let i = 0; i < digits.length ; i++){
    current = [digits[i],...current]
    if( current.length === n ){
      res = [...res, [...current]]
      current = []
    }
  }
  res = [...res, current]
  return res.reverse()
}

export const toCurrency = (n: number) => {
  const [integer, decimal] = n.toFixed(2).split(".");
  const raw = integer.replace("-","").split("")
  const result = group(raw, 3).map(x => x.join("")).filter(x => x.length !== 0).join(",") + "." + decimal
  return n < 0 ? `-${result}` : result
}

export const formatDate = (str: number) => {
  const d = new Date(str);

  const day = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][d.getDay()]
  const date = d.getDate()
  const month = d.getMonth() + 1
  const year = d.getFullYear()
  return `${day} ${date}/${month}/${year}`
}