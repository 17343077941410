import { useState } from "react"
import { Entry, UserRecord } from "../../core/models/Tab"
import { Box, Typography, Collapse, Avatar } from "@mui/material"
import { formatDate, toCurrency, entries } from "../../core/utils/functions"
import SummaryBalance from "../SummaryBalance"

interface ExpandablePaymentProps {
    entry: Entry,
    members: UserRecord
}

const ExpandablePayment = ({ entry, members }: ExpandablePaymentProps) => {
    const [open, setOpen] = useState(false)
    const {
        total,
        changes,
        note,
        payer,
        percentages,
        timestamp
    } = entry
    return <Box sx={{ display: "flex", flexDirection: "column", margin: "16px 6px" }} onClick={() => setOpen(x => !x)}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>{formatDate(timestamp)} - {note}</Typography>
        <Typography variant="subtitle1">${toCurrency(total)} payed by {members[payer].fullname}</Typography>
        <Collapse in={open}>
            {entries(members).map(([id, profile]) => {
                return <Box key={`disc-${id}`} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Avatar alt={profile.fullname} src={profile.picture} sx={{ width: 30, height: 30, mr: "8px" }}/>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body2">{profile.fullname}</Typography>
                        <Typography variant="body2">{percentages[id]}% {` `} <SummaryBalance value={changes[id]}/></Typography>
                    </Box>
                </Box>
            })}
        </Collapse>
    </Box>
}

export default ExpandablePayment