import getClassName from "getclassname";
import { tagged } from "../../core/utils/components";
import "./styles.scss"

type LoaderProps = {
    $alternate?: boolean,
    $small?: boolean 
}

const Loader = tagged.div<LoaderProps>`${({ $alternate, $small }) => {
    return getClassName({
        base: "one-loader",
        "&--alternate": $alternate,
        "&--small": $small
    })
}}`

export default Loader;