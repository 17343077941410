import { app } from "./firebase"
import { 
    getDatabase, 
    ref, 
    set, 
    get,
    onValue, 
    DatabaseReference, 
    DataSnapshot,
    push,
    remove
} from "firebase/database"
import { Maybe } from "jazzi/dist/Maybe/types"
import { default as M } from "jazzi/dist/Maybe"
import { Observable as O } from "jazzi-observable"
import { Observable } from "jazzi-observable/_types"

const db = getDatabase(app)

const observe = <T>(ref: DatabaseReference) => O
    .from<DataSnapshot>(obs => onValue(ref, s => obs.next(s)))
    .map(snap => M.fromCondition(() => snap.exists(), snap.val() as T))

const takeSnapshot = <T>(ref: DatabaseReference) => get(ref)
    .then(snap => M.fromCondition(() => snap.exists(), snap.val() as T))

export interface SmartRef<T = any> {
    dive<S extends keyof T>(sub: S): SmartRef<T[S]>,
    remove(): Promise<void>,
    set(val: T): Promise<void>,
    create(): Promise<string>,
    getSnapshot(): Promise<Maybe<T>>,
    getRef(): DatabaseReference,
    observe(): Observable<Maybe<T>, unknown>,
    exists(): Promise<boolean>
}

export const smartRef = <T>(path: string): SmartRef<T> => {
    return {
        dive(sub){ return smartRef(`${path}/${sub as string}`)},
        remove(){ return remove(this.getRef()) },
        set(val){ return set(this.getRef(), val)},
        create(){ return push(this.getRef()).then(x => x.key!) },
        getSnapshot(){ return takeSnapshot(this.getRef()) },
        getRef(){ return ref(db,path) },
        observe(){ return observe(this.getRef()) },
        exists(){ return this.getSnapshot().then(a => a.isJust()) }
    }
}

