import React from "react";
import { Route as RouteType } from "../../core/state/route";

interface RouteProps {
    path: RouteType,
    children: React.ReactNode
}

export const Route = ({ children }: RouteProps) => <>{children}</>

type RouteElement = React.ReactElement<RouteProps, typeof Route>

interface RouterProps {
    current: RouteType
    children: RouteElement[]
}

export const Router = ({ current, children }: RouterProps) => {
    const cs = React.Children.toArray(children) as RouteElement[]
    return cs.find(route => route.props.path === current) ?? null
}