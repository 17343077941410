import React, { useState } from "react";
import { 
    Avatar,
    Box,
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    ListItemAvatar, 
    MenuItem, 
    Select, 
    Slide, 
    TextField, 
    Typography
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Container } from "@mui/system";
import { entries } from "../../core/utils/functions";
import { Entry, UserRecord } from "../../core/models/Tab";
import { toUserId, UserEntry, UserId } from "../../core/models/Profile";
import FormattedTextField from "../FormattedTextField";
import Money from "../../core/structures/formatter/money";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" unmountOnExit ref={ref} {...props} />;
});

interface SimpleProps<T extends string> {
    open: boolean,
    title: string,
    submitText: string,
    fields: Record<T, string>,
    onSubmit: (data: Record<T, string>) => void,
    onClose: () => void
}

const memo: Record<string, Record<string, string>> = {}
const makeInit = <T extends string>(fields: Record<T,string>): Record<T, string> => {
    const key = Object.keys(fields).join(";")
    if( !memo[key] ){
        memo[key] = Object.keys(fields).reduce((acc, next) => {
            acc[next] = "";
            return acc
        },{} as Record<string, string>)
    }
    return memo[key]
}

export const SimpleDialogForm = <T extends string>({ open, title, fields, submitText, onSubmit, onClose }: SimpleProps<T>) => {
    const [data, setData] = useState(makeInit(fields))

    const handleAction = (kind: "close" | "submit") => () => {
        if( kind === "close" ){
            onClose()
        } else {
            onSubmit(data)
        }
        setData(makeInit(fields))
    }

    return <Dialog
        open={open}
        onClose={handleAction("close")}
        TransitionComponent={Transition}
        transitionDuration={500}
        maxWidth="lg"
        fullWidth
    >
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            <Container >
                {
                    entries(fields)
                    .map(([field, label]) => {
                        return <TextField
                            key={field}
                            margin="dense"
                            label={label} 
                            variant="standard"
                            fullWidth
                            value={data[field]}
                            onChange={e => setData(d => ({ ...d, [field]: e.target.value}))}
                        />
                    })
                }
            </Container>
        </DialogContent>
        <DialogActions>
            <Button variant="text" color="secondary" onClick={handleAction("close")}>
                Cancel
            </Button>
            <Button variant="text" onClick={handleAction("submit")}>
                {submitText}
            </Button>
        </DialogActions>
    </Dialog>
}

interface PromptProps {
    open: boolean,
    title: string,
    text: string,
    successText: string,
    onCancel: () => void,
    onSuccess: () => void
}

export const Prompt = ({ open, title, text, successText, onCancel, onSuccess }: PromptProps) => {
    return <Dialog
        open={open}
        onClose={onCancel}
        TransitionComponent={Transition}
        transitionDuration={500}
        maxWidth="lg"
        fullWidth
    >
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            <Typography variant="body1">
                {text}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button variant="text" color="secondary" onClick={onCancel}>
                Cancel
            </Button>
            <Button variant="text" onClick={onSuccess}>
                {successText}
            </Button>
        </DialogActions>
    </Dialog>
}

function generateSplit(splits: number) {
    const basePercentage = Math.floor(100 / splits)
    const arrSplits: number[] = Array(splits).fill(basePercentage)
    const extra = 100 % splits
    return [...arrSplits.slice(0, extra).map(x => x+1), ...arrSplits.slice(extra)]
}

function initializePercentages(memberArray: UserEntry[]) {
    const splitArray = generateSplit(memberArray.length)
    return memberArray.reduce((acc, next, idx) => {
        acc[next.id] = splitArray[idx]
        return acc
    }, {} as Record<UserId,number>)
}

interface PaymentFormProps {
    open: boolean
    members: UserRecord
    onCancel: () => void
    onSubmit: (entry: Omit<Entry, "id" | "timestamp">) => void
}

export const PaymentForm = ({ open, members, onSubmit, onCancel }: PaymentFormProps) => {
    const memberArray = entries(members).map(([,a]) => a)
    const [note, setNote] = useState("")
    const [payer, setPayer] = useState<UserId>(toUserId(""))
    const [total, setAmount] = useState(0)
    const [percentages, setPercentages] = useState(initializePercentages(memberArray))

    const handleSubmit = () => {
        const ps = entries(percentages)
        const totalPs = ps.reduce((acc, [, val]) => acc + val, 0)
        if( payer.length === 0 || totalPs !== 100 || note.trim().length === 0){
            return
        }
        const changes = ps.reduce((acc, [id, value]) => {
            acc[id] = (total/100) * value * -1
            return acc
        },{} as Record<UserId, number>)
        changes[payer] = changes[payer] + total
        onSubmit({
            changes,
            note: note.trim(),
            payer,
            percentages,
            total
        })
        setNote("")
        setAmount(0)
        setPercentages({})
        setPayer(toUserId(""))
    }
    return <Dialog
        open={open}
        onClose={onCancel}
        TransitionComponent={Transition}
        transitionDuration={500}
        fullScreen
    >
        <DialogTitle>
            New Payment
        </DialogTitle>
        <DialogContent>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems:"center" }}>
                <Box sx={{ marginBottom: "16px", width:"100%" }}>
                    <TextField 
                        label="Note" 
                        margin="dense"
                        variant="standard"
                        value={note}
                        multiline
                        fullWidth
                        maxRows={2}
                        onChange={e => setNote(e.target.value)}
                    />
                </Box>
                <Box sx={{ marginBottom: "16px", width:"100%" }}>
                    <Select
                        displayEmpty
                        fullWidth
                        value={payer}
                        onChange={e => setPayer(toUserId(e.target.value))}
                        renderValue={(val) => {
                            if( val.length === 0 ){
                                return <Typography color="grey.600">
                                    <em>Who payed?</em>
                                </Typography>
                            } else {
                                return members[val as any].fullname
                            }
                        }}
                    >
                        {memberArray.map(m => <MenuItem key={m.id} value={m.id}>
                            <ListItemAvatar>
                                <Avatar alt={m.fullname} src={m.picture} />
                            </ListItemAvatar>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <span>{m.fullname}</span>
                                <Typography key="what" color={m.active ? "success.main" : "error"} >{m.active ? "Active": "Inactive"}</Typography>
                            </Box>
                        </MenuItem>)}
                    </Select>
                </Box>
                <Box sx={{ marginBottom: "16px", width:"100%" }}>
                    <FormattedTextField 
                        label="How much?"
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={total > 0 ? `${total}` : "0"}
                        onChange={e => setAmount(parseFloat(e))}
                        formatter={Money}
                    />
                </Box>
                <Typography 
                    variant="h6" 
                    textAlign="left" 
                    sx={{ width: "100%", marginBottom: "8px"}}
                    color="grey.800"
                >
                    Percentages (%)
                </Typography>
                {memberArray.map(({ fullname, picture, id }) => {
                    return <Box key={`field;${id}`} sx={{ display: "flex", alignItems: "center" , marginBottom: "16px", width: "100%" }}>
                        <Avatar alt={fullname} src={picture} sx={{ mr: 1, my: 0.5 }}/>
                        <TextField
                            key={id}
                            label={fullname} 
                            margin="dense"
                            variant="standard"
                            fullWidth
                            value={percentages[id] > 0 ? percentages[id] : 0} 
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            onChange={e => setPercentages(c => ({...c, [id]: parseFloat(e.target.value)}))}
                        />
                    </Box>
                })}
            </Box>
        </DialogContent>
        <DialogActions>
            <Button variant="text" color="secondary" onClick={onCancel}>
                Cancel
            </Button>
            <Button variant="text" onClick={handleSubmit}>
                Submit
            </Button>
        </DialogActions>
    </Dialog>
}