import { User } from "firebase/auth";
import { UserId } from "../../core/models/Profile";
import { AuthAtom } from "../../core/state/auth";
import { wait } from "../../core/utils/functions";
import { getCurrentUser, getResult, signInWithGoogle, signOutGoogle } from "../../integration/firebase/auth";
import { ProfileService } from "../Profile/profile.service";

type VerificationResult = 
 | { loggedIn: true, user: User }
 | { loggedIn: false }

export class AuthService {
    static async verify(): Promise<VerificationResult> {
        const { loggedOut } = AuthAtom.get();
        await wait(1000)
        if( loggedOut ){
            AuthAtom.set({ loggedOut: false })
            return { loggedIn: false }
        }
        try {
            const user = getCurrentUser()
            if( user !== null ){
                await ProfileService.verify(user)
                return { loggedIn: true, user }
            }
            const creds = await getResult()
            return { loggedIn: true, user: creds.user }
        } catch {
            return { loggedIn: false }
        }
    }

    static async authenticate(){
        await wait(1000)
        await signInWithGoogle();
    }

    static async getProfile(id: UserId){
        return ProfileService.getProfile(id)
    }

    static async logout(){
        AuthAtom.set({ loggedOut: true })
        await Promise.all([
            new Promise((res) => setTimeout(res, 1000)),
            signOutGoogle()
        ])
    }
}