import { Typography } from "@mui/material"
import { toCurrency } from "../../core/utils/functions"

const SummaryBalance = ({ value }: { value: number }) => {
    if( value > 0 ){
        return <Typography 
            variant="body2" 
            component="span" 
            color="success.main"
        >
            $+{toCurrency(value)}
        </Typography>
    } else {
        return <Typography 
            variant="body2" 
            component="span" 
            color={value < 0 ? "error": "neutral"}
        >
            ${toCurrency(value)}
        </Typography>
    }
}

export default SummaryBalance