import { Iso } from "../optics/iso";
import { toFormatted } from "./formatter";

const groupBy = <A>(n: number, arr: A[]): A[][] => {
    return arr.reduceRight((groups, next) => {
        if( groups[groups.length - 1].length < n ){
            groups[groups.length - 1].push(next)
        } else {
            groups.push([ next ])
        }
        return groups
    },[[]] as A[][]).map(group => group.reverse()).reverse()
}

const Money = Iso.make(
    (str: string) => toFormatted(groupBy(3, str.split("")).map(x => x.join("")).join(",")),
    (formatted) => formatted.replaceAll(",", "")
)

export default Money