import { tagged } from "../../core/utils/components"
import gcn from "getclassname"
import "./styles.scss"

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

type CircularButtonProps = ButtonProps & {
    $alternate?: boolean
    $fill?: boolean
    $white?: boolean
}

const CircularButton = tagged.button`${({ $alternate, $fill, $white }: CircularButtonProps) => {
    return gcn({
        base: "one-circular-button",
        "&--alternate": $alternate,
        "&--fill": $fill,
        "&--white": $white 
    });
}}`

export default CircularButton