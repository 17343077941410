import { Maybe } from "jazzi/dist/Maybe/types"
import { Maybe as M } from "jazzi"
import { SetStateAction, useCallback, useEffect, useRef, useState } from "react"

export const useInterval = (fn: () => void, time: number) => {
    useEffect(() => {
        const id = setInterval(fn, time)

        return () => clearInterval(id)
    }, [time, fn])
}

export const useTimeout = (fn: () => void, time: number) => {
    useEffect(() => {
        const id = setTimeout(fn, time)

        return () => clearTimeout(id)
    }, [time, fn])
}

export const useLazyTimeout = (fn: () => void, time: number) => {
    let id: number | undefined = undefined;
    useEffect(() => () => {
        if( id !== undefined ){
            window.clearTimeout(id)
        }
    },[time, fn, id])

    return () => {
        id = window.setTimeout(fn, time)
    }
}

type PromiseState<T> = 
    | { state: "pending" }
    | { state: "success", data: T }
    | { state: "error", reason: unknown }

export const usePromise = <T>(promise: () => Promise<T>) => {
    const [state, setState] = useState<PromiseState<T>>({ state: "pending" })

    useEffect(() => {
        promise()
        .then(
            (data) => setState({ state: "success", data }),
            (reason) => setState({ state: "error", reason })
        )
    }, [promise])

    return state
}

type LazyPromiseState<T> = 
 | { state: "waiting" }
 | PromiseState<T>

type IgnorePromise = () => void

type PromiseHandle<T,Input> = LazyPromiseState<T> & {
    runPromise(i: Input): IgnorePromise
}

export const useLazyPromise = <T, Input>(promise: (i: Input) => Promise<T>): PromiseHandle<T,Input> => {
    const [state, rawSetState] = useState<LazyPromiseState<T>>({ state: "waiting" })

    const mountRef = useRef(true)
    useEffect(() => {
        mountRef.current = true
        return () => {mountRef.current = false}
    },[])

    const setState = (arg: SetStateAction<LazyPromiseState<T>>) => {
        if( mountRef.current ){
            rawSetState(arg)
        }
    }

    const handle = {
        ...state,
        runPromise: (i: Input) => {
            setState({ state: "pending" })
            promise(i)
            .then(
                (data) => setState({ state: "success", data }),
                (reason) => setState({ state: "error", reason})
            )

            return () => {
                mountRef.current = false
            }
        }
    }

    return handle
}

export const useMaybe = <T,>() => {
    const [m, setM] = useState<Maybe<T>>(M.None())
    const cb = useCallback((t?: T) => setM(M.fromNullish(t)), [setM])
    return [m, cb] as const
}

export const useBooleanStates = <T extends readonly string[]>(states: T) => {
    const initial = states.reduce((acc, next) => {
        acc[next as T[number]] = false
        return acc
    },{} as Record<T[number], boolean>)
    const [flags, setFlags] = useState(initial)
    const on = useCallback((key: T[number]) => () => setFlags(f => ({...f, [key]: true })),[])
    const off = useCallback((key: T[number]) => () => setFlags(f => ({...f, [key]: false })),[])
    const trigger = useCallback((key: T[number]) => () => setFlags(f => ({...f, [key]: !f[key] })),[])
    return [flags, trigger, on, off] as const
}