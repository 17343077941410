import { initializeApp } from "firebase/app"

const getEnv = (str: string) => process.env[`REACT_APP_${str}`]

const firebaseConfig = {
  apiKey: getEnv("API_KEY"),
  authDomain: getEnv("AUTH_DOMAIN"),
  databaseURL: getEnv("DATABASE_URL"),
  projectId: getEnv("PROJECT_ID"),
  storageBucket: getEnv("STORAGE_BUCKET"),
  messagingSenderId: getEnv("SENDER_ID"),
  appId: getEnv("APP_ID"),
}

export const app = initializeApp(firebaseConfig)